import styled from "styled-components"
import { DEVICES } from "src/constants"

const Link = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  @media ${DEVICES.laptopL} {
    font-size: 22px;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  img {
    margin-left: 5px;
  }
`

export default Link
